import { defineStore } from "pinia";
import { ref } from "vue";
import { getLoginUserUsingGet } from "@/api/userController";
import ACCESS_ENUM from "@/access/accessEnum";

export const useLoginUserStore = defineStore("loginUser", () => {
  // 初始化时从localStorage读取状态
  const initialLoginUser = localStorage.getItem("loginUser");
  const initialHasFetched = localStorage.getItem("hasFetched") === "true";

  const loginUser = ref<API.LoginUserVO>(
    initialLoginUser
      ? JSON.parse(initialLoginUser)
      : { userRole: ACCESS_ENUM.NOT_LOGIN }
  );
  const hasFetched = ref(initialHasFetched);

  // 设置用户信息
  function setLoginUser(newLoginUser: API.LoginUserVO) {
    loginUser.value = newLoginUser;
    localStorage.setItem("loginUser", JSON.stringify(newLoginUser));
  }

  // 清除用户信息
  function clearLoginState() {
    loginUser.value = { userRole: ACCESS_ENUM.NOT_LOGIN };
    localStorage.removeItem("loginUser");
    hasFetched.value = false;
  }

  // 获取登录用户信息
  async function fetchLoginUser() {
    if (hasFetched.value) {
      return;
    }
    try {
      // 调用后端接口获取用户信息
      const res = await getLoginUserUsingGet();
      if (res.data.code === 0 && res.data.data) {
        setLoginUser(res.data.data);
        hasFetched.value = true;
      } else {
        clearLoginState(); // 如果获取失败，清除登录状态
      }
    } catch (error) {
      console.error("无法获取登录用户:", error);
      clearLoginState();
    }
  }

  return {
    loginUser,
    setLoginUser,
    clearLoginState,
    fetchLoginUser,
    hasFetched,
  };
});
