import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { id: "userLoginPage" }
const _hoisted_2 = { style: {"display":"flex","width":"100%","align-items":"center","justify-content":"space-between"} }

import { reactive, ref } from "vue";
import API from "@/api";
import { userLoginUsingPost } from "@/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";


export default /*@__PURE__*/_defineComponent({
  __name: 'UserLoginPage',
  setup(__props) {

const loginUserStore = useLoginUserStore();
const router = useRouter();
const isLoading = ref(false);

const form = reactive({
  userAccount: "",
  userPassword: "",
} as API.UserLoginRequest);

/**
 * 登录
 */
const handleSubmit = async () => {
  isLoading.value = true;
  // 检查表单是否完整
  if (!form.userAccount || !form.userPassword) {
    message.error("请输入完整信息！");
    isLoading.value = false;
    return;
  }
  try {
    // 调用后端登录接口
    const res = await userLoginUsingPost(form);
    if (res.data.code === 0) {
      // 登录成功，获取用户信息
      const token = res.headers.authorization;
      if (token) {
        localStorage.setItem("token", token);
      }
      const userData = res.data.data;
      // @ts-ignore 将用户信息存储到 Pinia 和 localStorage
      loginUserStore.setLoginUser(userData);
      loginUserStore.hasFetched = true;
      localStorage.setItem("hasFetched", "true");
      // 提示并跳转
      message.success("登录成功");
      await router.push({
        path: "/home",
        replace: true,
      });
    } else {
      if (res.data.message === "用户已登录") {
        message.loading("用户已登录，正在跳转主页...");
        await router.push({
          path: "/home",
          replace: true,
        });
      } else {
        message.error("登录失败，" + res.data.message);
      }
    }
  } catch (error) {
    console.error("登录错误:", error);
    message.error("登录失败，请检查网络或稍后再试");
  } finally {
    isLoading.value = false;
  }
};

return (_ctx: any,_cache: any) => {
  const _component_a_input = _resolveComponent("a-input")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_password = _resolveComponent("a-input-password")!
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_link = _resolveComponent("a-link")!
  const _component_a_form = _resolveComponent("a-form")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _cache[4] || (_cache[4] = _createElementVNode("h2", { style: {"margin-bottom":"16px"} }, "用户登录", -1)),
    _createVNode(_component_a_form, {
      model: form,
      style: { width: '480px', margin: '0 auto' },
      "label-align": "left",
      "auto-label-width": "",
      onSubmit: handleSubmit
    }, {
      default: _withCtx(() => [
        _createVNode(_component_a_form_item, {
          field: "userAccount",
          tooltip: "账号可以是数字也可以是英文字母，最多20个字符",
          label: "账号",
          rules: [{ required: true, message: '此项为必填' }]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input, {
              modelValue: form.userAccount,
              "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.userAccount) = $event)),
              placeholder: "请输入账号"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, {
          field: "userPassword",
          tooltip: "密码不小于 8 位",
          label: "密码",
          rules: [{ required: true, message: '此项为必填' }]
        }, {
          default: _withCtx(() => [
            _createVNode(_component_a_input_password, {
              modelValue: form.userPassword,
              "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.userPassword) = $event)),
              placeholder: "请输入密码"
            }, null, 8, ["modelValue"])
          ]),
          _: 1
        }),
        _createVNode(_component_a_form_item, null, {
          default: _withCtx(() => [
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_a_button, {
                type: "primary",
                "html-type": "submit",
                style: {"width":"120px"}
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode(" 登录 ")
                ])),
                _: 1
              }),
              _createVNode(_component_a_link, { href: "/user/register" }, {
                default: _withCtx(() => _cache[3] || (_cache[3] = [
                  _createTextVNode("新用户注册")
                ])),
                _: 1
              })
            ])
          ]),
          _: 1
        })
      ]),
      _: 1
    }, 8, ["model"])
  ]))
}
}

})