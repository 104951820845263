<template>
  <div id="userLoginPage">
    <h2 style="margin-bottom: 16px">用户登录</h2>
    <a-form
      :model="form"
      :style="{ width: '480px', margin: '0 auto' }"
      label-align="left"
      auto-label-width
      @submit="handleSubmit"
    >
      <a-form-item
        field="userAccount"
        tooltip="账号可以是数字也可以是英文字母，最多20个字符"
        label="账号"
        :rules="[{ required: true, message: '此项为必填' }]"
      >
        <a-input v-model="form.userAccount" placeholder="请输入账号" />
      </a-form-item>
      <a-form-item
        field="userPassword"
        tooltip="密码不小于 8 位"
        label="密码"
        :rules="[{ required: true, message: '此项为必填' }]"
      >
        <a-input-password
          v-model="form.userPassword"
          placeholder="请输入密码"
        />
      </a-form-item>
      <a-form-item>
        <div
          style="
            display: flex;
            width: 100%;
            align-items: center;
            justify-content: space-between;
          "
        >
          <a-button type="primary" html-type="submit" style="width: 120px">
            登录
          </a-button>
          <a-link href="/user/register">新用户注册</a-link>
        </div>
      </a-form-item>
    </a-form>
  </div>
</template>

<script setup lang="ts">
import { reactive, ref } from "vue";
import API from "@/api";
import { userLoginUsingPost } from "@/api/userController";
import { useLoginUserStore } from "@/store/userStore";
import message from "@arco-design/web-vue/es/message";
import { useRouter } from "vue-router";

const loginUserStore = useLoginUserStore();
const router = useRouter();
const isLoading = ref(false);

const form = reactive({
  userAccount: "",
  userPassword: "",
} as API.UserLoginRequest);

/**
 * 登录
 */
const handleSubmit = async () => {
  isLoading.value = true;
  // 检查表单是否完整
  if (!form.userAccount || !form.userPassword) {
    message.error("请输入完整信息！");
    isLoading.value = false;
    return;
  }
  try {
    // 调用后端登录接口
    const res = await userLoginUsingPost(form);
    if (res.data.code === 0) {
      // 登录成功，获取用户信息
      const token = res.headers.authorization;
      if (token) {
        localStorage.setItem("token", token);
      }
      const userData = res.data.data;
      // @ts-ignore 将用户信息存储到 Pinia 和 localStorage
      loginUserStore.setLoginUser(userData);
      loginUserStore.hasFetched = true;
      localStorage.setItem("hasFetched", "true");
      // 提示并跳转
      message.success("登录成功");
      await router.push({
        path: "/home",
        replace: true,
      });
    } else {
      if (res.data.message === "用户已登录") {
        message.loading("用户已登录，正在跳转主页...");
        await router.push({
          path: "/home",
          replace: true,
        });
      } else {
        message.error("登录失败，" + res.data.message);
      }
    }
  } catch (error) {
    console.error("登录错误:", error);
    message.error("登录失败，请检查网络或稍后再试");
  } finally {
    isLoading.value = false;
  }
};
</script>
