import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

import { reactive, ref } from "vue";
import API from "@/api";
import { aiGenerateQuestionUsingPost } from "@/api/questionController";
import message from "@arco-design/web-vue/es/message";
import { isDev } from "@/request";

interface Props {
  appId: string;
  onSuccess?: (result: API.QuestionContentDTO[]) => void;
  onSSESuccess?: (result: API.QuestionContentDTO) => void;
  onSSEStart?: (event: any) => void;
  onSSEClose?: (event: any) => void;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'AiGenerateQuestionDrawer',
  props: {
    appId: { default: () => {
    return "";
  } },
    onSuccess: {},
    onSSESuccess: {},
    onSSEStart: {},
    onSSEClose: {}
  },
  setup(__props: any) {

const props = __props;

const form = reactive({
  optionNumber: 4,
  questionNumber: 5,
} as API.AiGenerateQuestionRequest);

const visible = ref(false);
const submitting = ref(false);
const sseSubmitting = ref(false);
const buttonDisable = ref(false);

const handleClick = () => {
  visible.value = true;
};
const handleOk = () => {
  visible.value = false;
};
const handleCancel = () => {
  visible.value = false;
};

/**
 * 提交
 */
const handleSubmit = async () => {
  if (!props.appId) {
    return;
  }
  submitting.value = true;
  buttonDisable.value = true;
  const res = await aiGenerateQuestionUsingPost({
    appId: props.appId as any,
    ...form,
  });
  if (res.data.code === 0 && res.data.data.length > 0) {
    if (props.onSuccess) {
      props.onSuccess(res.data.data);
    } else {
      message.success("生成题目成功");
    }
    // 关闭抽屉
    handleCancel();
  } else {
    message.error("操作失败，" + res.data.message);
  }
  submitting.value = false;
  buttonDisable.value = false;
};

/**
 * 提交（实时生成）
 */
const handleSSESubmit = async () => {
  message.loading("正在尝试建立连接，请稍后...");
  if (!props.appId) {
    return;
  }
  sseSubmitting.value = true;
  buttonDisable.value = true;
  // 动态构建 EventSource URL
  const baseURL = isDev
    ? "http://localhost:8080"
    : "https://springboot-dkx2-129206-8-1331347804.sh.run.tcloudbase.com";
  const tokenUrl = localStorage.getItem("token");
  const sseUrl =
    `${baseURL}/api/question/ai_generate/q_wen_sse` +
    `?appId=${props.appId}&optionNumber=${form.optionNumber}
    &questionNumber=${form.questionNumber}&token=${tokenUrl}`;
  // 创建 SSE 请求
  const eventSource = new EventSource(sseUrl, {
    withCredentials: true,
  });
  let first = true;
  // 接收消息
  eventSource.onmessage = function (event) {
    if (first) {
      props.onSSEStart?.(event);
      handleCancel();
      first = !first;
    }
    props.onSSESuccess?.(JSON.parse(event.data));
  };
  // 报错或连接关闭时触发
  eventSource.onerror = function (event) {
    if (event.eventPhase === EventSource.CLOSED) {
      console.log("关闭连接");
      props.onSSEClose?.(event);
      sseSubmitting.value = false;
      buttonDisable.value = false;
      eventSource.close();
    } else {
      sseSubmitting.value = false;
      buttonDisable.value = false;
      eventSource.close();
    }
  };
};

return (_ctx: any,_cache: any) => {
  const _component_a_button = _resolveComponent("a-button")!
  const _component_a_form_item = _resolveComponent("a-form-item")!
  const _component_a_input_number = _resolveComponent("a-input-number")!
  const _component_a_tooltip = _resolveComponent("a-tooltip")!
  const _component_a_space = _resolveComponent("a-space")!
  const _component_a_form = _resolveComponent("a-form")!
  const _component_a_drawer = _resolveComponent("a-drawer")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_a_button, {
      status: "warning",
      onClick: handleClick
    }, {
      default: _withCtx(() => _cache[2] || (_cache[2] = [
        _createTextVNode("AI 生成题目")
      ])),
      _: 1
    }),
    _createVNode(_component_a_drawer, {
      width: 340,
      visible: visible.value,
      onOk: handleOk,
      onCancel: handleCancel,
      unmountOnClose: ""
    }, {
      title: _withCtx(() => _cache[3] || (_cache[3] = [
        _createTextVNode("AI 生成题目")
      ])),
      default: _withCtx(() => [
        _createElementVNode("div", null, [
          _createVNode(_component_a_form, {
            model: form,
            "label-align": "left",
            "auto-label-width": ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_a_form_item, { label: "应用 id" }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.appId), 1)
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                field: "questionNumber",
                label: "题目数量"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_number, {
                    min: "0",
                    max: "50",
                    modelValue: form.questionNumber,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((form.questionNumber) = $event)),
                    placeholder: "请输入题目数量"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form_item, {
                field: "optionNumber",
                label: "选项数量"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_a_input_number, {
                    min: "0",
                    max: "6",
                    modelValue: form.optionNumber,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((form.optionNumber) = $event)),
                    placeholder: "请输入每题的选项数量"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }),
              _createVNode(_component_a_form, null, {
                default: _withCtx(() => [
                  _createVNode(_component_a_space, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_a_tooltip, {
                        content: "推荐，可以实时看到题目的生成。如果在移动端不可用，请尝试一键生成",
                        position: "bottom"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            loading: sseSubmitting.value,
                            disabled: buttonDisable.value,
                            onClick: handleSSESubmit,
                            type: "primary",
                            style: {"width":"150px"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(sseSubmitting.value ? "请稍后..." : "实时生成"), 1)
                            ]),
                            _: 1
                          }, 8, ["loading", "disabled"])
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_a_tooltip, {
                        content: "时间较长，需要等待所有题目生成完毕。",
                        position: "left"
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_a_button, {
                            loading: submitting.value,
                            disabled: buttonDisable.value,
                            onClick: handleSubmit,
                            type: "outline",
                            style: {"width":"150px"}
                          }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(submitting.value ? "生成中..." : "一键生成"), 1)
                            ]),
                            _: 1
                          }, 8, ["loading", "disabled"])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["model"])
        ])
      ]),
      _: 1
    }, 8, ["visible"])
  ], 64))
}
}

})