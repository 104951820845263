import router from "@/router";
import { useLoginUserStore } from "@/store/userStore";
import ACCESS_ENUM from "@/access/accessEnum";
import checkAccess from "@/access/checkAccess";
import { storeToRefs } from "pinia";

router.beforeEach(async (to, from, next) => {
  const loginUserStore = useLoginUserStore();
  // 使用storeToRefs解构响应式变量
  const { loginUser, hasFetched } = storeToRefs(loginUserStore);
  // 如果是登录/注册页直接放行
  if (to.path === "/user/login" || to.path === "/user/register") {
    return next();
  }

  // 如果已登录且本地有Token，直接放行（避免重复请求）
  if (localStorage.getItem("token") && loginUser.value.userRole !== ACCESS_ENUM.NOT_LOGIN) {
    return next();
  }

  // 如果未获取过用户信息且本地有Token，尝试静默登录
  if (!hasFetched.value && localStorage.getItem("token")) {
    try {
      await loginUserStore.fetchLoginUser();
    } catch (error) {
      // Token无效时清除本地状态
      localStorage.removeItem("token");
      loginUserStore.clearLoginState();
    }
  }
  // 权限检查逻辑
  const requiredAccess :any = to.meta?.access ?? ACCESS_ENUM.NOT_LOGIN;
  if (requiredAccess !== ACCESS_ENUM.NOT_LOGIN) {
    // 使用.value访问Ref
    const isLoggedIn =
      loginUser.value?.userRole &&
      loginUser.value.userRole !== ACCESS_ENUM.NOT_LOGIN;

    if (!isLoggedIn) {
      return next(`/user/login?redirect=${to.fullPath}`);
    }

    // 传递原始值给checkAccess
    if (!checkAccess(loginUser.value, requiredAccess)) {
      return next("/noAuth");
    }
  }
  // 如果无需登录或权限检查，直接放行
  next();
});
